.App{
	background: linear-gradient(to bottom, #f4c8ac 0%, #bf6a30 100%);
}
.tags {
	text-align: center;
	padding: 20px 0px;
	background-color:#F4C8AC;
}

.tag {
	outline: none;
	border: none;
	font-size: large;
	padding: 6px;
	font-family: Arial, Helvetica, sans-serif;
	color: rgb(255, 255, 255);
	margin: 2px 4px;
	background-color: #BF6A30;
	border-radius: 15px;
	cursor: pointer;
}

.active {
	font-weight:bold;
	color:#5E2612;
	border-bottom: 7px solid #B3432B;
}
.container {
	width: 80%;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

.image-card {
	padding: 5px;
	margin: 5px;
	border: 1px solid #ddd;
	background-color: #eee;
	border-radius: 10px;
}

.image {
	width: 100%;
	border-radius: 10px;
}

@media screen and (max-width: 768px){
    .container{
        grid-template-columns: repeat(2,1fr)
    }
    }
    